import axios from 'axios';
import Config from '../services/Config';
import SigV4Utils from "../lib/SigV4Utils";
import Paho from "paho-mqtt";

const ReInitMQTTProtocolCommunication = async (UserID,deviceName,resolve)=>{





    let credentials= {
        sessionToken: Config.sessionToken,
        accessKeyId: Config.accessKeyId,
        secretAccessKey: Config.secretAccessKey
    };
    let region = Config.region;
    let host = Config.deviceEndpoint;

    let url = await SigV4Utils.getSignedUrl(host, region, credentials)
    let clientId = UserID;
    let client = new Paho.Client(url, clientId);
    let mqttShadowName = Config.deviceName;
    let connectOptions = {
        onSuccess: () => {

            client.subscribe('$aws/things/' + mqttShadowName + '/shadow/update/delta', {
                onSuccess: (res) => {
                    let ping_for_get = new Paho.Message("");
                    ping_for_get.destinationName = '$aws/things/'+mqttShadowName+'/shadow/get';
                    client.send(ping_for_get);
                },
                onFailure: (error) => {
                    console.log(error);
                }
            })
            client.subscribe('$aws/things/' + mqttShadowName + '/shadow/update/accepted', {
                onSuccess: (res) => {
                    let ping_for_get = new Paho.Message("");
                    ping_for_get.destinationName = '$aws/things/'+mqttShadowName+'/shadow/get';
                    client.send(ping_for_get);
                },
                onFailure: (error) => {
                    console.log(error);
                }
            })
            client.subscribe('$aws/events/presence/connected/' + mqttShadowName, {
                onSuccess: () => {
                    client.subscribe('$aws/events/presence/disconnected/' + mqttShadowName, {
                        onSuccess: () => {
                            console.log('MQTT', 'subscribed to disconnected');
                        },
                        onFailure: (err) => {
                            console.log(err);
                        }
                    });
                    client.subscribe('$aws/things/' + mqttShadowName + '/shadow/get/accepted', {
                        onSuccess: () => {
                            let ping_for_get = new Paho.Message("");
                            ping_for_get.destinationName = '$aws/things/' + mqttShadowName + '/shadow/get';
                            client.send(ping_for_get);
                        }
                    });

                }, onFailure: (err) => {
                    console.log('Subscription Failed', err);
                }
            });
        },
        useSSL: true,
        timeout: 3,
        mqttVersion: 4,
        onFailure: (err) => {
            console.log('MQTT Failed', err);
        }


    };

    // this.client.onConnectionLost = this.onConnectionLost.bind(this);
    client.connect(connectOptions);

    resolve({
        MQTTClient: client
    });
}

const initMQTTProtocolCommunication = async (sessionToken, accessKeyId, secretAccessKey, UserID, deviceName, signInResponse, profileResponse, userProfileResponse, resolve) => {
    let credentials = {
        sessionToken: sessionToken,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    };
    let region = Config.region;
    let host = Config.deviceEndpoint;

    let url = await SigV4Utils.getSignedUrl(host, region, credentials)
    let clientId = UserID;
    let client = new Paho.Client(url, clientId);
    let mqttShadowName = deviceName;
    let connectOptions = {
        onSuccess: () => {

            client.subscribe('$aws/things/' + mqttShadowName + '/shadow/update/delta', {
                onSuccess: () => {
                    console.log("ok")
                },
                onFailure: (error) => {
                    console.log(error);
                }
            })
            client.subscribe('$aws/things/' + mqttShadowName + '/shadow/update/accepted', {
                onSuccess: () => {
                    console.log("ok")
                },
                onFailure: (error) => {
                    console.log(error);
                }
            })
            client.subscribe('$aws/events/presence/connected/' + mqttShadowName, {
                onSuccess: () => {
                    client.subscribe('$aws/events/presence/disconnected/' + mqttShadowName, {
                        onSuccess: () => {
                            console.log('MQTT', 'subscribed to disconnected');
                        },
                        onFailure: (err) => {
                            console.log(err);
                        }
                    });
                    client.subscribe('$aws/things/' + mqttShadowName + '/shadow/get/accepted', {
                        onSuccess: () => {
                            let ping_for_get = new Paho.Message("");
                            ping_for_get.destinationName = '$aws/things/' + mqttShadowName + '/shadow/get';
                            client.send(ping_for_get);
                        }
                    });

                }, onFailure: (err) => {
                    console.log('Subscription Failed', err);
                }
            });
        },
        useSSL: true,
        timeout: 3,
        mqttVersion: 4,
        onFailure: (err) => {
            console.log('MQTT Failed', err);
        }


    };

    // this.client.onConnectionLost = this.onConnectionLost.bind(this);
    client.connect(connectOptions);

    resolve({
        authToken: signInResponse.data.token,
        role: signInResponse.data.role,
        accessKeyId: signInResponse.data.IOTcred.AccessKeyId,
        updatePassword : signInResponse.data.updatePassword,
        TemporaryPassword : signInResponse.data.user.TemporaryPassword,
        secretAccessKey: signInResponse.data.IOTcred.SecretAccessKey,
        sessionToken: signInResponse.data.IOTcred.SessionToken,
        userProfile: userProfileResponse,
        profile: {
            email: signInResponse.data.profile?.Email,
            clinicName: profileResponse.data.ClinicName,
            userId: signInResponse.data.profile?.UserID,
            deviceName: profileResponse.data?.DeviceID,
            siteAdmin: profileResponse.data?.SiteAdmin,
            clinicEmail: profileResponse.data?.ClinicEmail,
            clinicPhone: profileResponse.data?.ClinicPhone,
            clinicZip: profileResponse.data?.ClinicZip,
            clinicLocation: profileResponse.data?.Location,
            clinicId: profileResponse.data?.ClinicID,
            createdAt: profileResponse.data?.CreatedUTC,
        },
        MQTTClient: client
    });
}

export const forgetPassword = (data) =>
    new Promise((resolve, reject) => {
        let body = {
            Email: data.toLowerCase()
        }
        let url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/forgot/`
        axios.post(url, body)
            .then(async (response) => {
                resolve({forgetPasswordStatus: "true", error: null});
            })
            .catch(async (error) => {
                console.log(error)
                if (error) {
                    reject({forgetPasswordStatus: "error", error: error});

                }
            });

    })

export const registerUser = (data) =>
    new Promise((resolve, reject) => {
        console.log(data)
        let body = {

            FirstName: data.firstName,
            validContactName: true,
            validContactLastName: true,
            LastName: data.lastName,
            IsEnabled: true,
            ClinicName: data.clinicName,
            ClinicID: data.clinicName,
            Email: data.email.toLowerCase(),
            ClinicPhone: data.phone,
            ClinicZip: data.zip,
            Password: data.password,
            Location: {
                Country: data.country,
                State: data.state,
                City: data.city,
                Address: data.clinicAddress
            }
        }


        axios.post(`${Config.globalUrl}/api/v1/webapp/siteadmins/requestClinicSiteAdmin`, body)
            .then(async (response) => {
                console.log(JSON.stringify(response));
                resolve({userCreated: "true", error: null});
            })
            .catch(async (error) => {
                reject({userCreated: "error", error: error});

                console.log(error);
            });

    })

export const resetPassword=(password,rePassword,authToken=null)=>
    new Promise((resolve,reject)=>{
        let token = authToken ? true : window.location.href.indexOf('?token=') > -1;
        if(token === true){
            token = authToken ? authToken : window.location.href.split('?token=');
            if(token.length > 1 || authToken){
                token = token[1]

                let body = {
                    Token:authToken ? authToken : token,
                    Password:password
                }

                let url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/forgot/ResetConfirm/`

                axios.post(url,body)
                    .then(async (response) => {
                        resolve(response)
                    })
                    .catch( async (error) => {
                        console.log(error);
                        if(error.response.data.error && error.response.data.error.message){
                            reject({error: error.response.data.error.message})
                        }else if(error.response.data.error && !error.response.data.error.message){
                            reject({error: error.response.data.error})
                        }else{
                            reject({error: error.response.data.error})
                        }
                    });

            }else{
                reject({error:"No Auth Token"})
                return
            }
        }else{
            reject({error:"No Auth Token"})
            return;
        }

    })

export const reconnectMqtt=(UserID,deviceName)=>
    new Promise(async (resolve, reject) => {
            ReInitMQTTProtocolCommunication(UserID, deviceName, resolve)
        });


const Check2Fa =(signInResponse,resolve)=>{
    console.log(signInResponse.data.code)
    resolve({
        await2fa:true,
        temp2faToken:signInResponse.data.token2fa,
    })
}
const FillAdminData =(signInResponse)=>{
    return {
        authToken: signInResponse.data.token,
        role: signInResponse.data.role,
        accessKeyId: signInResponse.data.IOTcred.AccessKeyId,
        secretAccessKey: signInResponse.data.IOTcred.SecretAccessKey,
        sessionToken: signInResponse.data.IOTcred.SessionToken,
        userProfile: null,
        profile: {
            email: signInResponse.data.user.Email,
            clinicName: null,
            userId: signInResponse.data.user.Email,
            deviceName:     null,
            siteAdmin:      null,
            clinicEmail:    null,
            clinicPhone:    null,
            clinicZip:      null,
            clinicLocation: null,
            clinicId:       null,
            createdAt:      null,
        },
        MQTTClient: null
    }
}
const FillUserData =  (signInResponse,username,resolve)=>{
    console.log("Fill User Data !")
    axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${signInResponse.data.profile.UserID}/getMyClinicInfo/`, {
        headers: {
            'Authorization': signInResponse.data.token
        }
    }).then(async (profileResponse) => {
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${signInResponse.data.profile.UserID}/profile`, {headers: {'Authorization': signInResponse.data.token}})
            .then(async (userProfile) => {

                console.log(userProfile)
                if (signInResponse.data.role.indexOf('Doctor') > -1) {
                    signInResponse.data.Email = signInResponse.data.profile.Email;
                    signInResponse.data.UserID = signInResponse.data.profile.UserID;
                    signInResponse.data.deviceName = signInResponse.data.profile.DeviceID;
                } else if (signInResponse.data.role.indexOf('Admin') > -1 || signInResponse.data.role.indexOf('SiteAdmin') > -1) {
                    signInResponse.data.UserID = username;
                }


                initMQTTProtocolCommunication(
                    signInResponse.data.IOTcred.SessionToken,
                    signInResponse.data.IOTcred.AccessKeyId,
                    signInResponse.data.IOTcred.SecretAccessKey,
                    signInResponse.data.profile?.UserID,
                    profileResponse.data?.DeviceID,
                    signInResponse,
                    profileResponse,
                    userProfile,
                    resolve
                );

            }).catch(async (error) => {
            console.log(error);
        });
    }).catch(async (error) => {
        console.log(error);
    });
}
const RoleCheck =(signInResponse)=>{
    let isAdmin = signInResponse.data.role.indexOf('Admin') > -1;
    let isSiteAdmin = signInResponse.data.role.indexOf('SiteAdmin') > -1
    let isDoctor = signInResponse.data.role.indexOf('Doctor') > -1
    return {
        isAdmin,
        isSiteAdmin,
        isDoctor
    }
}
export const Confirm2faAdmin =(token,code)=>
    //localhost/api/v1/webapp/signin/2fa
    //TwoFaCode
    new Promise(async (resolve,reject)=>{
        let body = {
            TwoFaCode: code.trim(),
        }
        await axios.post(`${Config.globalUrl}/api/v1/webapp/signin/2fa`, body,{headers: {'Authorization':token}})
            .then(async (signInResponse) => {
                console.log("2fa - ok !")
                let _r = RoleCheck(signInResponse);

                if (_r.isAdmin) {
                    resolve(FillAdminData(signInResponse));
                }else if(_r.isSiteAdmin){
                    FillUserData(signInResponse,null,resolve)
                }

            })
            .catch(async (error) => {
                reject(error)
                console.log(error);
            });
    })
export const login = (username, password,reCaptchaToken, callback, flag = null) =>
    new Promise(async (resolve, reject) => {
        let body = {
            Email: username.trim().toLowerCase(),
            Password: password,
            reCaptchaToken:reCaptchaToken
        }
        await axios.post(`${Config.globalUrl}/api/v1/webapp/signin`, body)
            .then(async (signInResponse) => {
                console.log("Logged - in !")
                if (signInResponse.data.role.indexOf('Admin') > -1) {
                    resolve(FillAdminData(signInResponse)) // OK
                } else if(signInResponse.data.role.indexOf('SiteAdmin') > -1) {
                    Check2Fa(signInResponse,resolve)
                }
                else {
                    await FillUserData(signInResponse, username, resolve)
                }

            })
            .catch(async (error) => {
                reject(error)
                console.log(error);
            });
    });

export const logout = () =>
    new Promise((resolve, reject) => {
        // let body = {
        //     Email: data
        // }
        // let url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/forgot/`
        // axios.post(url, body)
        //     .then(async (response) => {
                resolve(true);
            // })
            // .catch(async (error) => {
            //     console.log(error)
            //     if (error) {
            //         reject({forgetPasswordStatus: "error", error: error});
            //
            //     }
            // });

    })



const handleResetPassword = (event) => {
    event.preventDefault();
    var re = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$");
    var _test = re.test(this.state.confirmpass);
    this.setState({Valid: _test, Match: this.state.confirmpass === this.state.regpassword})

    if (!this.state.Match || !this.state.Valid) {
        return;
    }
    var body = {
        Token: window.location.href.split('?token=')[1],
        Password: this.state.regpassword
    }
    event.preventDefault();
    // this.setState({ isLoading: true });
    var url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/forgot/ResetConfirm/`
    axios.post(url, body)
        .then(async (response) => {
            await this.setState({isPaswordResetSent: true})
            setTimeout(
                function () {
                    this.props.history.push("/Login")
                }
                    .bind(this),
                3000
            );

        })
        .catch(async (error) => {
            console.log(error);
        });
};

const getBackendVersion = () => {
    this.getDataAndUpdateState('GET', `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/version`, null, 'beVersion');
}

// const updDoctorProfile=(body)=>{
//      this.getDataAndUpdateState('POST',`${Config.globalUrl}:${Config.globalPort}/api/doctors/profile/${Config.UserID}`,body,null);
// }

// const setTestData=(body)=>{
//      this.getDataAndUpdateState('POST',`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/TestData/`,body,null);
// }
const getTestDataById = (id) => {
    this.getDataAndUpdateState('GET', `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/TestData/?TestID=${id}`, null, 'currentTestData');
}
const getAllLabResults = () => {
    this.getDataAndUpdateState('GET', `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/getAllLabTestResults`, null, 'labResults');
}
const handleForgotPass = async event => {

}
const getCurrentTestByID = () => {
    // {{url}}:80/api/v1/webapp/doctors/{{unameD}}/TestData/?TestID=27


    axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/TestData?TestID=${this.props.TestID}`, Config.token)
        .then(async (response) => {
            await this.setState({summaryData: response.data});
        })
        .catch(async (error) => {
            console.log(error);
        });
}
const sendEmail = (test) => {
    axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/emailTestResult/?TestID=${test.TestID}`, Config.token)
        .then(async (response) => {
            alert('email sent')
        })
        .catch(async (error) => {
            alert(error)
            console.log(error);
        });
}


// const getClinicalTrialData=()=> {
//     // api/v1/webapp/doctors/doctor1/getClinicalTrailData
//     axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${Config.UserID}/getClinicalTrailData`, Config.token)
//         .then(async (response) => {
//
//             // DoctorsProgress:Array(0) []
//             // Name:"bacterial vaginosis2"
//             // Target:"10"
//             // TotalProgress:"0"
//             response.data.forEach(lab => {
//                 var myProgress = lab.DoctorsProgress.filter(x => x.UserID === Config.UserID)[0];
//                 if (myProgress) {
//                     lab.My = myProgress.ProgressVal;
//                 } else {
//                     lab.My = 0;
//                 }
//                 lab.TotalProgress = 0;
//                 lab.DoctorsProgress.forEach(doctorProgress=>{
//                     lab.TotalProgress += doctorProgress.ProgressVal;
//                 })
//             });
//
//             await this.setState({ globalTrialData: response.data });
//
//
//             let maxTrialTarget = Math.max.apply(Math, this.state.globalTrialData.map(function (o) { return o.Target; })); //get maximum target
//             var chartValues = this.prepareChartXAxisValues(maxTrialTarget);
//             await this.setState({ //set state and update view
//                 globalTrialDataMaxValue: maxTrialTarget,
//                 chartXAxisValues: chartValues
//             });
//
//         })
//         .catch(async (error) => {
//             console.log(error);
//         });
// }
