import React from 'react';
import GSmallLabel from "./GSmallLabel";
import GLeftNumberBox from "./GLeftNumberBox";
import GBigLabel from "./GBigLabel";
import {useTranslation} from "react-i18next";
import HowTo1Img from '../assets/howto1.png'
const GHowToConnectScannerStep2 = (props) => {
    const { t, i18n } = useTranslation()
    return (
      <div>
          <GBigLabel className={"mt-8 text-center lg:mt-1.5rem text-1.375rem sm:text-2rem "}>
              {t("connect.title")}
          </GBigLabel>
          <GBigLabel className={"text-center text-0.875rem sm:text-1.25rem "}>
              {t("connect.step-2-title")}
          </GBigLabel>
          <div className={"g-step-body mb-8 mt-8 lg:mb-2.438rem lg:mt-4rem "}>
              <div className={"flex "}>
                  {/*h-20.938rem*/}
                  <div className={" sm:h-auto sm:w-2/3"}>
                      <GLeftNumberBox number={1}>
                          {t("connect.step-2-p1")}
                      </GLeftNumberBox>
                      <GLeftNumberBox nodash number={2}>
                          {t("connect.step-2-p2")}
                      </GLeftNumberBox>
                  </div>
                  <div className={"hidden sm:block w-1/3"}>
                      <div className={"g-steps-phone-image  m-auto w-4rem h-11rem sm:w-11.188rem sm:h-23.5rem"}>
                        <img alt='howto1' src={HowTo1Img}/>
                      </div>
                  </div>
              </div>
          </div>


      </div>
    )
};
export default GHowToConnectScannerStep2;
