import React from 'react';
import GSmallLabel from "./GSmallLabel";
import GLeftNumberBox from "./GLeftNumberBox";
import GBigLabel from "./GBigLabel";
import { useTranslation } from "react-i18next";
import HowTo2Img from '../assets/howto2.png'
const GHowToConnectScannerStep3 = (props) => {
    const { t, i18n } = useTranslation()
    return (
        <div>
            <GBigLabel className={"mt-8 text-center lg:mt-1.5rem text-1.375rem sm:text-2rem"}>
                {t("connect.title")}
            </GBigLabel>
            <GBigLabel className={"text-center text-0.875rem sm:text-1.25rem "}>
                {t("connect.step-3-title")}
            </GBigLabel>
            <div className={"g-step-body  mb-8 mt-8 lg:mb-2.438rem lg:mt-4rem "}>
                <div className={"flex "}>
                    {/*h-20.938rem*/}
                    <div className={" h-43.125rem sm:h-auto sm:w-2/3"}>
                        <GLeftNumberBox number={1}>
                            {t("connect.step-3-p1")}
                            <div className={"mt-0.4rem mb-0.4rem"}>
                                <GSmallLabel className={"text-0.75rem"}>{t("connect.step-3-p1-list-title")}</GSmallLabel>
                                <div>
                                    <div className={"flex"}><div className={"w-1/10 mt-1 mr-1"}><div className={"w-1.5 h-1.5 rounded-full bg-#1396B4"} /></div><div className={"w-1/2"}><GSmallLabel className={"text-0.75rem"} >{t("connect.step-3-p1-list-1")}</GSmallLabel></div></div>
                                    <div className={"flex"}><div className={"w-1/10 mt-1 mr-1"}><div className={"w-1.5 h-1.5 rounded-full bg-#1396B4"} /></div><div><GSmallLabel className={"text-0.75rem"} >{t("connect.step-3-p1-list-2")}</GSmallLabel></div></div>
                                    <div className={"flex"}><div className={"w-1/10 mt-1 mr-1"}><div className={"w-1.5 h-1.5 rounded-full bg-#1396B4"} /></div> <div><GSmallLabel className={"text-0.75rem"} >{t("connect.step-3-p1-list-3")}{t("connect.step-1-url")}</GSmallLabel></div></div>
                                    <div className={"flex"}><div className={"w-1/10 mt-1 mr-1"}><div className={"w-1.5 h-1.5 rounded-full bg-#1396B4"} /></div> <div><GSmallLabel className={"text-0.75rem"} >{t("connect.step-3-p1-list-4")}</GSmallLabel></div></div>
                                </div>
                            </div>

                        </GLeftNumberBox>
                        <GLeftNumberBox number={2}>
                            {t("connect.step-3-p2")}
                        </GLeftNumberBox>
                        <GLeftNumberBox number={3}>
                            {t("connect.step-3-p3")}
                        </GLeftNumberBox>
                        <GLeftNumberBox nodash number={4}>
                            {t("connect.step-3-p4")}
                        </GLeftNumberBox>
                    </div>
                    <div className={"hidden sm:block w-1/3"}>
                        <div className={"g-steps-phone-image  m-auto w-4rem h-11rem sm:w-11.188rem sm:h-23.5rem"}>
                            <img alt='howto2' src={HowTo2Img} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};
export default GHowToConnectScannerStep3;
