import React, { useContext, useEffect } from 'react';
import GSmallLabel from "../GSmallLabel";
import { BackendContext } from "../../machines/BackendMachine";
import { useActor } from "@xstate/react";
import { DeviceContext } from "../../machines/DeviceMachine";
import { useTranslation } from "react-i18next";
import { TestContext } from "../../machines/TestMachine";
import Config from "../../services/Config";

const StatusIcon = (props) => {
    const { t, i18n } = useTranslation()

    const BEContext = useContext(BackendContext)
    const [BackendCurrentState, Send] = useActor(BEContext);

    const DContext = useContext(DeviceContext)
    const [DeviceCurrentStatus, SetStatus] = useActor(DContext);

    const TContext = useContext(TestContext)
    const [TestCurrentState, UpdateTestState] = useActor(TContext);

    useEffect(() => {
        console.log(" !!! MQTTClient !!! ")
        if (BackendCurrentState.context.MQTTClient) {
            BackendCurrentState.context.MQTTClient.onMessageArrived = function MMM(message) {
                console.log("MQTT MESSAGE", message)
                if (message && message.payloadString) {
                    let _payload = JSON.parse(message.payloadString)
                    if (_payload && _payload.state && _payload.state.reported && _payload.state.reported.deviceStatusCode) {
                        let _deviceStatusCode = _payload.state.reported.deviceStatusCode;
                        if (_deviceStatusCode !== DeviceCurrentStatus.context.statusCode) {

                            onMQTTMessage(message);
                        }
                    }
                } else {
                    console.log("MQTTMessage But Status Code not changed , to be ignored.")
                }
            };
        }
    })




    const onMQTTMessage = (message) => {
        let mqttMessageStringAsJson = JSON.parse(message.payloadString);
        let statusCode = -1;
        let DeviceID = Config.deviceName;
        switch (message.topic) {
            case '$aws/things/' + DeviceID + '/shadow/update/delta':
            case '$aws/things/' + DeviceID + '/shadow/update/accepted':
            case '$aws/things/' + DeviceID + '/shadow/get/accepted':
                if (!mqttMessageStringAsJson.state.reported) {
                    return
                }
                if (mqttMessageStringAsJson.state.reported) {
                    if (mqttMessageStringAsJson.state.reported.online === 0) {
                        statusCode = 999;
                    } else {
                        statusCode = Number(mqttMessageStringAsJson.state.reported.deviceStatusCode);
                    }
                }
                break;
            case '$aws/events/presence/connected/' + DeviceID:
                statusCode = 1000;
                break;
            case '$aws/events/presence/disconnected/' + DeviceID:
                statusCode = 999;
                break;
            default:
                break;
        }

        SetStatus({ type: "SET_CURRENT_DEVICE_STATUS", status: statusCode });
    }



    return (
        <div className={"flex space-x-3 text-#666767 " + props.className}>
            {
                DeviceCurrentStatus.context.deviceStatusCode !== 987
                    ?
                    <div className={"h-8 top-2.5 right-1 relative mr-1"}>
                        <div className={(DeviceCurrentStatus.context.isBlinking ? " animate-ping " : " ") + " absolute border flex m-auto h-3 w-3 rounded-full bg-" + DeviceCurrentStatus.context.indicatorColor + "-500 "}></div>
                        <div className={"absolute border flex m-auto h-3 w-3 rounded-full bg-" + DeviceCurrentStatus.context.indicatorColor + "-500 "}></div>
                    </div>
                    :
                    null
            }

            <div className={"h-8 flex "}>
                <GSmallLabel className={"text-sm m-auto whitespace-nowrap"}>
                    {
                        DeviceCurrentStatus.context.statusSubMessage
                    }
                </GSmallLabel>
            </div>
        </div>
    )
};
export default StatusIcon;

